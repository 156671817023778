import C from "./forms/BillingSelect.module.css";

import type { BillingAccountCardData } from "@warrenio/api-spec/spec.oats.gen";
import { mcn } from "../../utils/baseProps.ts";
import type { BillingAccountCardWithData } from "./billingCardsQuery.ts";
import { getCardIconClass, getMethodLinkIconClass } from "./iconClasses.tsx";
import type { PaymentMethodId } from "./PaymentMethod.tsx";

//#region Icons

function getPaymentMethodIconClass(card: BillingAccountCardWithData) {
    return getCardIconClass(card.parsedAdditionalData);
}

export function PaymentMethodIcon({ card, ...props }: { card: BillingAccountCardWithData }) {
    return <span {...mcn(`${C.MethodIcon} inline-block ${getPaymentMethodIconClass(card)}`, props)} />;
}

export interface PaymentLinkIconProps {
    icon: PaymentMethodId;
    isSelected?: boolean;
}

export function PaymentLinkIcon({ icon, isSelected = false, ...props }: PaymentLinkIconProps) {
    return <span {...mcn(`${C.MethodIcon} inline-block ${getMethodLinkIconClass(icon, isSelected)}`, props)} />;
}

//#endregion

export type CardShortInfoData = Pick<
    BillingAccountCardData,
    "card_type" | "brand" | "type" | "last4" | "expire_month" | "expire_year"
>;

/** In top up selector and BA detail view */
export function CardShortInfo({ card }: { card: BillingAccountCardWithData }) {
    return <PrimaryCardShortInfo card={card.parsedAdditionalData} />;
}

export function PrimaryCardShortInfo({ card }: { card: CardShortInfoData }) {
    const { type } = card;
    if (type === "paying_by_invoice") {
        return <span>Paying By Invoice</span>;
    } else {
        const { last4, expire_month, expire_year, card_type, type } = card;
        const brandText = card_type || type;
        return (
            <span>
                <span className="text-capitalize">{last4 ? `${brandText}*${last4}` : brandText}</span>
                <span className="color-muted">
                    {" "}
                    ({expire_month}/{expire_year})
                </span>
            </span>
        );
    }
}

/** In Billing Account List view */
export function PrimaryCardBlockInfo({ card }: { card: CardShortInfoData }) {
    const { last4, expire_month, expire_year, card_type, type } = card;
    const brandText = card_type || type;
    return (
        <span>
            <span>{last4 ? `*${last4}` : brandText}</span>
            <span>
                , {expire_month}/{expire_year}
            </span>
        </span>
    );
}
